@media print {
  @page {
    size: A4 landscape;
  }

  .swe-print-hide {
    visibility: hidden;
  }

  .swe-print-show {
    visibility: visible;
    position: fixed;
    left: 0;
    top: 0;
  }
  


  .swe-print-remove {
    display: none !important;
  }

  .swe-print-add {
    display: inline-block !important;
  }

  button {
    display: none !important;
  }

  .table-responsive {
      overflow-x: auto !important;
  }

  .swe-print-full-width {
    width: auto !important;
    max-width: auto !important;
  }
}

/*Only visible when print*/
.swe-print-add {
  display: none;
}

