/*Include all of Bootstrap-Icons*/
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";

/*Browser defaults********************/
html {
    font-size: 14px;
}
/*************************************/

/*Quill Custom CSS********************/
.ql-img {
  max-width: 100%;
}
/*************************************/

/*Screen Reader***********************/
.sr-only {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}


.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto
}
/*************************************/

.modal-video-url {
  position: absolute;
  left: 0;
  z-index: 1999;
  display: none;
  overflow-x: hidden;
  overflow-y: hidden;
  top: 0;
  outline: 0;
}

.disabled-div {
      pointer-events: none;
  opacity: 0.5;

}

/*FontAwesome hacks*******************/
/*
.fa-swe {
  font-size: 1.3em;
}

.fa-swe-letter-circle {
  font-size: 1.5em;
}

.fa-swe-letter-circle-small {
  font-size: 1.21em;
}

.fa-swe-letter {
  color: var(--bs-white);
  text-transform: uppercase;
}

.fa-swe-letter-o {
  color: var(--bs-dark);
  text-transform: uppercase;
}
.fa-swe-white {
  color: var(--bs-white);
}
*/
/*************************************/

/*Bootstrap-icons hack****************/
.bi {
    display:inline-block;
    line-height: 1;
}
/*Size*/
.bi-swe {
  font-size: 1.3em;
}
.bi-swe-topmenu {
  font-size: 1.6rem !important;
}
.bi-swe-2x {
  font-size: 2rem !important;
}
.bi-swe-3x {
  font-size: 3rem !important;
}
.bi-swe-4x {
  font-size: 4rem !important;
}
.bi-swe-5x {
  font-size: 5rem !important;
}
.bi-swe-6x {
  font-size: 6rem !important;
}
.bi-swe-7x {
  font-size: 7rem !important;
}
.bi-swe-8x {
  font-size: 8rem !important;
}
.bi-swe-9x {
  font-size: 9rem !important;
}
.bi-swe-10x {
  font-size: 10rem !important;
}
/*Width*/
.bi-swe-fw {
  text-align: center;
  width: 1.25em;
}
/*Border*/
.bi-swe-border {
  border: 1px solid var(--bs-gray-200);
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
/*Inverse*/
.bi-swe-inverse {
  color: white !important;
}
/*Right*/
.bi-swe-pull-right {
  float: right;
  margin-left: 0.3em;
}
/*Left*/
.bi-swe-pull-left {
  float: left;
}
@media (max-width: 575px) {
  /*XS devices*/
  .bi-swe-xs {
    font-size: 2em;
  }
  .bi-swe-xs-pull-right {
    margin-left: 0.6em;
  }
}
/*Rotate*/
.bi-swe-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}
.bi-swe-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.bi-swe-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}
/*Spin*/
.bi-swe-spin {
  animation: 2s linear infinite bi-swe-spin
}
@keyframes bi-swe-spin {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}
/*Stack*/
.bi-swe-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em
}
.bi-swe-stack-1x, .bi-swe-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%
}

.bi-swe-stack-1x {
  line-height: inherit
}

.bi-swe-stack-2x {
  font-size: 2em
}
/*Letter*/
.bi-swe-letter-circle {
  font-size: 1.5em;
}

.bi-swe-letter-circle-small {
  font-size: 1.21em;
}

.bi-swe-letter {
  color: var(--bs-white);
  text-transform: uppercase;
}

.bi-swe-letter-o {
  color: var(--bs-dark);
  text-transform: uppercase;
}
/*BookingTypes*/
.bi-swe-bookingtype-normal::before {
  content: "";
}
.bi-swe-bookingtype-replaceable::before {
  content: "\f64f";
}
.bi-swe-bookingtype-standby::before {
  content: "\f4d4";
  color: gray;
}
.bi-swe-bookingtype-abscent::before {
  content: "\f2e5";
}
.bi-swe-bookingtype-decline::before {
  content: "\f622";
}
/*************************************/


/*App*********************************/
.swe-app {
  display: flex;
  width: 100%;
  align-items: stretch;
}
/*************************************/

.swe-top-40 {
    top: 40%;
}

/*Top menu****************************/
.swe-topmenu {
  height: 3.5rem;
}
/*************************************/

/*Bot menu****************************/
.swe-botmenu {
    height: 3.8rem;
}

.swe-large-botmenu {
  height: 5rem !important;
}
/************************************/

/*Sidebar*****************************/
.swe-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15rem; 
  padding-right: 0;
  margin-left: -15rem;
  min-height: 100vh;
  /*transition: linear 0.5s;*/
  height: 100%;
  overflow: hidden;
  z-index: 10000;
}
.swe-sidebar-show .swe-sidebar-inside {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 2rem; /*Fix so content will not disapear when hidding scrollbar*/
  box-sizing: content-box; /*So the width will be 100% + 17px*/
}
.swe-sidebar.swe-sidebar-show {
  margin-left: 0;
}
/*************************************/

/*Nav link*/
.swe-nav-link {
    color: var(--bs-white) !important;
}
.swe-nav-link:hover {
  background-color: var(--bs-gray-600);
  /*Make hover background expand*/
  margin-left: -15px;
  padding-left: 15px;
  margin-right: -30px;
  padding-right: 30px;
}


/*Content wrapper*********************/
.swe-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 1px; /*No unnessasary bottom scroll*/
  min-height: 100% /*100vh make site "jumping"*/;
  /*transition: linear 0.5s;*/
}

.swe-content-wrapper.swe-sidebar-show {
  left: 0; 
}

.swe-content-wrapper.swe-searchbar-show {
  right: 0;
}
@media(min-width: 768px) {
  .swe-content-wrapper.swe-searchbar-show-md {
    right: 23rem;
  }
}
/*************************************/



/*Searchbar***************************/
.swe-searchbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 23rem;
  margin-right: -23rem;
  min-height: 100vh;
  /*transition: linear 0.5s;*/
  height: 100%;
  overflow: auto;
  z-index: 10000;
}

.swe-searchbar.swe-searchbar-show {
  margin-right: 0;
}
/*************************************/



/*Clickable Area**********************/
.swe-clickable-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-white);
  opacity: 0.8;
  z-index: 1000;
}
@media(min-width: 768px) {
  .swe-clickable-area-md {
    background-color: transparent;
    opacity: 0;
  }
}
.swe-element-clickable-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  z-index: 999;
}

/*************************************/



/*Container***************************/
.swe-row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
@media(max-width: 767px) {
  .swe-row {
    margin-left: 0;
    margin-right: 0;
  }
}
@media(max-width: 767px) {
  .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media(max-width: 767px) {
  .swe-fill-to-edge {
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
    padding-right: 2px;
    display: block;
  }
}
/*************************************/


/*Border******************************/
.border-swe {
  border-color: var(--bs-gray-400);
}
/*************************************/


/*Alerts******************************/
.swe-alert {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 5px;
  right: 0;
  padding-right: 5px;
  z-index: 2000;
}
@media (min-width: 768px) {
  .swe-alert-md {
    position: fixed;
    top: 0;
    left: 20%;
    padding-left: 5px;
    right: 20%;
    padding-right: 5px;
    z-index: 2000;
  }
}
.swe-alert i {
    cursor: pointer;
}
.swe-alert-text {
  color: var(--bs-dark) !important;
}
.swe-alert-history {
    width: 200px;
}
@media (min-width: 768px) {
  .swe-alert-history {
    width: 600px;
  }
}
/*************************************/



/*Waiting*****************************/
.swe-waiting {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
/*************************************/

.swe-popup {
    width:100%;
    overflow-y:auto;
    z-index: 1000;
    max-height: 80%;
    
}

@media (min-width: 768px) {
  .swe-popup {
    width: 80%;
  }
}
@media (min-width: 1200px) {

  .swe-popup {
      width:60%;
  }
}
@media (min-width: 1400px) {
  .swe-popup {
      width:40%;
      min-height:600px;
  }

}

.swe-video-wrapper {
  width: 424px;
  height: 238px;
}
.swe-video-wrapper  iframe{
  width: 100%;
  height: 100%;
}
/*Circle Image************************/
.swe-circle-image {
  border: solid 1px var(--bs-gray-400);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
/*************************************/



/*Click object************************/
.swe-click {
  cursor: pointer;
}
/*************************************/


/*Click object************************/
.swe-disabled {
  cursor: not-allowed !important;
}
/*************************************/


/*Grayed Out**************************/
.swe-grayed-out {
  color: var(--bs-gray-400) !important;
}
.swe-grayed-out-alt {
  color: var(--bs-gray-600) !important;
}
.swe-bg-grayed-out {
  background-color: var(--bs-gray-400) !important;
}
.swe-badge-grayed-out {
  background-color: var(--bs-gray-400) !important;
  color: var(--bs-white);
}
/*************************************/



/*Badge*******************************/
.swe-badge {
  font-weight: normal !important;
  font-size: 1em;
}
/*************************************/




/*Scrollable**************************/
.swe-scrollable {
  height: 200px;
  overflow-y: scroll;
}
/*************************************/


/*Time********************************/
.swe-time {
  width: 70px;
}
/*************************************/


/*Button SwedeTime********************/
.btn-swe-unstyle {
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btn-swe {
  color: var(--bs-gray-800);
  background-color: var(--bs-white);
  border-color: var(--bs-gray-300)
}
.btn-swe:hover {
  color: var(--bs-gray-800);
  background-color: var(--bs-gray-200);
  border-color: var(--bs-gray-300)
}
.btn-swe.focus, .btn-swe:focus {
  color: var(--bs-gray-800);
  background-color: var(--bs-gray-200);
  border-color: var(--bs-gray-300);
  box-shadow: 0 0 0 .2rem rgba(27,110,194,.25)
}
.btn-swe.disabled, .btn-swe:disabled {
  color: var(--bs-gray-800);
  background-color: var(--bs-white);
  border-color: var(--bs-gray-300)
}
.btn-swe:not(:disabled):not(.disabled).active, .btn-swe:not(:disabled):not(.disabled):active, .show > .btn-swe.dropdown-toggle {
  color: var(--bs-gray-800);
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}
.btn-swe:not(:disabled):not(.disabled):not(.btn-swe-primary).active:focus, .btn-swe:not(:disabled):not(.disabled):not(.btn-swe-primary):active:focus, .show > .btn-swe.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(27,110,194,.25)
}
/*************************************/

/*Button SwedeTime primary Active********/
  .btn-swe-primary:not(:disabled):not(.disabled).active, .btn-swe:not(:disabled):not(.disabled):active, .show > .btn-swe.dropdown-toggle {
    color: var(--bs-white);
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
  }
/*************************************/

/*Row Icons***************************/
.swe-row-icon {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  width: 100%;
}
.swe-row-icon .swe-col-icon {
  float: left;
    width: 35px;
  padding-top: 8px;
  padding-right: 0;
}

.swe-row-icon .swe-col-content {
  -webkit-box-flex: 1; /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1; /* OLD - Firefox 19- */
  width: 20%; /* For old syntax, otherwise collapses. */
  -webkit-flex: 1; /* Chrome */
  -ms-flex: 1; /* IE 10 */
  flex: 1; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  padding-left: 0;
}
/*************************************/




/*Modal*******************************/
.swe-fade {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.swe-fade.show
{
  opacity: 1;
}
/*************************************/




/*Timeline****************************/
.swe-timeline-header {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
}
.swe-timeline-mark-row {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: -5px;
  cursor: pointer;
  border: solid 2px var(--bs-dark);
}
.swe-booking-block {
  position: absolute;
  height: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  color: var(--bs-dark);
}
.swe-usermarkeddate-block {
  position: absolute;
  height: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-size: 100% 6px !important;
  background-color: var(--bs-white);
  cursor: pointer;
  padding-left: 3px;
  color: var(--bs-dark);
}
.swe-news-block {
  position: absolute;
  height: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-size: 100% 6px !important;
  cursor: pointer;
  padding-left: 3px;
  color: var(--bs-dark);
}
.swe-detail-block {
  position: absolute;
  height: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  z-index: 100;
  color: var(--bs-dark);
}
.swe-disabled-block {
  background-color: var(--bs-gray-200) !important;
  border-color: var(--bs-gray-500) !important;
  color: var(--bs-gray-500) !important;
  cursor: not-allowed !important;
}
.swe-reference-block {
  background-color: var(--bs-gray-200) !important;
  border-color: var(--bs-gray-500);
  color: var(--bs-dark) !important;
}
.swe-timeline-weekday {
  background-color: var(--bs-gray-200);
}
.swe-timeline-holiday {
  background: repeating-linear-gradient( 135deg, var(--bs-holiday), var(--bs-holiday) 10px, var(--bs-white) 10px, var(--bs-white) 20px ); 
}
.swe-timeline-holiday-red {
  background: repeating-linear-gradient( 135deg, var(--bs-holidayred), var(--bs-holidayred) 10px, var(--bs-white) 10px, var(--bs-white) 20px );
}
.swe-timeline-abscent-full {
  background: repeating-linear-gradient( 135deg, var(--bs-alert-success), var(--bs-alert-success) 10px, var(--bs-gray-200) 10px, var(--bs-gray-200) 20px );
  border-color: var(--bs-gray-500);
}
.swe-timeline-abscent-full-help {
  background: repeating-linear-gradient( 135deg, var(--bs-alert-success), var(--bs-alert-success) 5px, var(--bs-gray-200) 5px, var(--bs-gray-200) 10px );
  border-color: var(--bs-gray-500);
}
.swe-timeline-abscent-notfull {
  background: repeating-linear-gradient( 135deg, var(--bs-alert-danger), var(--bs-alert-danger) 10px, var(--bs-gray-200) 10px, var(--bs-gray-200) 20px );
  border-color: var(--bs-gray-500);
}
.swe-timeline-abscent-notfull-help {
  background: repeating-linear-gradient( 135deg, var(--bs-alert-danger), var(--bs-alert-danger) 5px, var(--bs-gray-200) 5px, var(--bs-gray-200) 10px );
  border-color: var(--bs-gray-500);
}
.swe-timeline-minustime {
  background-image: radial-gradient(var(--bs-white) 30%, transparent 30%), radial-gradient(var(--bs-white) 30%, transparent 30%);
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-color: var(--bs-alert-danger) !important;
}
.swe-timeline-minustime-help {
  background-image: radial-gradient(var(--bs-white) 30%, transparent 30%), radial-gradient(var(--bs-white) 30%, transparent 30%);
  background-position: 0px 0px, 5px 5px;
  background-size: 10px 10px;
  background-color: var(--bs-alert-danger) !important;
}
.swe-timeline-plustime {
  background-image: radial-gradient(var(--bs-white) 30%, transparent 30%), radial-gradient(var(--bs-white) 30%, transparent 30%);
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-color: var(--bs-alert-success) !important;
}
.swe-timeline-plustime-help {
  background-image: radial-gradient(var(--bs-white) 30%, transparent 30%), radial-gradient(var(--bs-white) 30%, transparent 30%);
  background-position: 0px 0px, 5px 5px;
  background-size: 10px 10px;
  background-color: var(--bs-alert-success) !important;
}
.swe-timeline-item-text {
  pointer-events: none;
  position: relative;
  z-index: 1; /*Make text float over opacity blocks*/
}
/*************************************/


/*Calendar Detail*/
.swe-detail-header {
  padding-left: 3px;
}
/*****************/

/*Table*/
.swe-table {
  width: 100%;
  max-width: 100%;
  /*margin-bottom: 20px;*/
}

  .swe-table > thead > tr > th,
  .swe-table > tbody > tr > th,
  .swe-table > tfoot > tr > th,
  .swe-table > thead > tr > td,
  .swe-table > tbody > tr > td,
  .swe-table > tfoot > tr > td {
    padding: 5px;
    vertical-align: top;
  }

.swe-table-condensed > thead > tr > th,
.swe-table-condensed > tbody > tr > th,
.swe-table-condensed > tfoot > tr > th,
.swe-table-condensed > thead > tr > td,
.swe-table-condensed > tbody > tr > td,
.swe-table-condensed > tfoot > tr > td {
  padding: 1px;
}

.swe-table-block {
  border: 1px solid var(--bs-gray-400);
  border-radius: 4px;
  padding-left: 3px;
  margin-bottom: 30px;
}
/********/



/*DragAndDrop*************************/
.swe-drop-target {
  border: 2px dashed var(--bs-dark);
}
/*************************************/


/*Scrolling**************************/
.swe-scroll-spot {
  position: fixed;
  left: 0;
  margin-left: 50rem;
  right: 0;
  margin-right: 50rem;
  border: 2px dashed var(--bs-dark);
  cursor: pointer;
  text-align: center;
  z-index: 1000;
}
.swe-scroll-spot.up {
  top: 3.5rem;
}
.swe-scroll-spot.down {
  bottom: 1.5rem;
}
/*************************************/





/*Weeknumber*/
.swe-calendar-week {
  background-color: var(--bs-gray-200);
  font-size: 75%;
  vertical-align: middle !important;
}
/*************************************/



/*Make dropdowns go over sticky top*/
.swe-reset-sticky-top {
  z-index: 997 !important;
}
.swe-sticky-top {
  top: 2.9rem !important;
  z-index: 999;
}
.swe-sticky-top-2 {
  top: 6.9rem !important;
  z-index: 998;
}
.swe-sticky-top-3 {
  top: 10.3rem !important;
  z-index: 997;
}
.swe-sticky-top.scroll-active {
/*  background-color: var(--bs-white) !important;*/
/*  border: 1px solid rgba(0, 0, 0, 0.125);*/
}
.swe-sticky-top-2.scroll-active {
  background-color: var(--bs-white) !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.swe-sticky-top-3.scroll-active {
  background-color: var(--bs-white) !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
}


/*InfoText*/
.swe-info-text {
  font-style: italic;
  background-color: lightgrey;
  border: 1px solid grey;
  margin: 0 0 10px;
  padding: 9.5px;
  border-radius: 4px;
}

.swe-adminmode-text {
  font-style: italic;
  border: 1px solid grey;
  margin: 0 0 10px;
  padding: 9.5px;
  border-radius: 4px;
}

/*History*/
.swe-history {
  font-style: italic;
  background-color: lightgrey;
  border: 1px solid grey;
  margin: 0 0 10px;
  padding: 9.5px;
  border-radius: 4px;
  width: 300px;
}
.swe-history-item {
  border-top: 1px solid var(--bs-gray-400);
}
.swe-history-column {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  margin-right: 2px;
}


/*Icons*/
.swe-icon-color {
    color:var(--bs-dark);
}


/*Backgrounds*/
.swe-bg-light {
  background-color: var(--bs-gray-100);
}


/*No link*/
.swe-no-link {
  color: inherit;
  text-decoration: none;
}
.swe-no-link:hover {
  color: inherit;
  text-decoration: none;
}
.swe-no-link:active {
  color: inherit;
  text-decoration: none;
}
.swe-no-link:focus {
  outline: 1px solid var(--bs-gray-400);
}
.swe-no-link.swe-no-focus:focus {
  outline: none;
}


.swe-active-cell {
  border-top: 3px solid var(--bs-dark) !important;
  border-bottom: 3px solid var(--bs-dark) !important;
}
.swe-active-cell.swe-first-cell {
  border-left: 3px solid var(--bs-dark) !important;
}
.swe-active-cell.swe-last-cell {
  border-right: 3px solid var(--bs-dark) !important;
}




.swe-invalid-profile {
  border: 3px solid;
}

/*Make room for contents (takes height) in a relative-absolute position element*/
.swe-contents {
  display:contents !important;
}





.swe-table-marker-top {
  border-top-style: solid !important;
  border-top-width: 2px !important;
  border-top-color: var(--bs-primary) !important;
}
.swe-table-marker-left {
  border-left-style: solid !important;
  border-left-width: 2px !important;
  border-left-color: var(--bs-primary) !important;
}
.swe-table-marker-right {
  border-right-style: solid !important;
  border-right-width: 2px !important;
  border-right-color: var(--bs-primary) !important;
}
.swe-table-marker-bottom {
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
  border-bottom-color: var(--bs-primary) !important;
}

kbd {
  color: var(--bs-black);
  text-shadow: 0 1px 0 var(--bs-white);
  background-color: var(--bs-gray-200);
  border: 1px solid var(--bs-gray-400);
  border-radius: 3px;
  box-shadow: 0 1px 1px hsla(210, 8%, 5%, 0.15), inset 0 1px 0 0 var(--bs-white);
}

.swe-textbox-as-button {
  height: 33.5px;
  width: 100px;
  padding: 5.250px 10.500px;
  border: 1px solid var(--bs-gray-400) !important;
  border-radius: 3.5px;
}


.swe-icon-save-btn {
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border: 2px solid var(--bs-primary) !important;
  border-radius: 3.5px;
}


/*Big text for small displays*/
.swe-big-header {
  font-size: 2.50rem !important;
  font-weight: 500 !important;
}
.swe-big-text {
  font-size: 2.00rem !important;
}


.swe-checkboxlist-container {
  border: 1px solid #CCC;
  overflow-y:auto;
}
.swe-checkboxlist-item {
  margin-left: 2px;
}

.swe-dot-under-text {
  /*text-decoration-style: double;
  text-decoration-color: black;
  text-decoration-line: underline;
  text-decoration-thickness: from-font;*/
  display: inline-block;
  position: relative;
  text-align: center;
}
.swe-dot-under-text::before {
  content: '.';
  display: inline-block;
  /* position the dot underneath the character */
  position: absolute;
  bottom: -0.45em;
  left: 0;
  /* center the dot horizontally */
  text-align: center;
  width: 100%;
  /* style */
  font-size: 3em;
}


.swe-w-25 {
  width: 25% !important;
}

/*Auto scroll in swe-list*/
.swe-scroll-to {
  
}


.swe-bg-warning-half {
  background: linear-gradient(to bottom, var(--bs-warning) 50%, transparent 50%); /* Go towards the bottom, Cover BS Warning for 50%, Cover #transparent for the other half */
}




@media screen {
  /*Not for print*/
  .swe-report-table {
      height: var(--swe-report-height);
      box-sizing: border-box;
    }
  }
